video {
  object-fit: cover;
  width: 100%;
  height: 130%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('') center center/cover no-repeat; */
  /* background-color: black; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: white;
  font-size: 100px;
  margin-top: -70px;
  font-family: 'Sarpanch', sans-serif;
  text-shadow: 2px 2px black;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Sarpanch', sans-serif;
  text-shadow: 2px 2px black;
}

.hero-btns {
  margin-top: 18px;
  color: white;
  font-size: medium;
}

.hero-btns:hover {
  cursor: pointer;
  transform: scale(1.4);
  transition: all 0.5s ease-out;
  color: white;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {    
    font-size: 70px;
    margin-top: -150px;   
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {   
    /* background-color: green;  */
    font-size: 35px;
    margin-top: -150px;   
  }

  .hero-container > p {
    font-size: 15px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}