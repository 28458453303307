:root {
  --primary: white;
}

.btn {
  padding: 8px 20 px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: gray;
  border: 1px solid var(--primary)
}

.btn--outline {
  background-color: transparent;
  color: white;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

btn--medium:hover, .btn--large:hover {
  background: white;
  color: gray;
  transition: all 0.3s ease-out;
}