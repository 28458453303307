body {
  background-color: rgb(0, 0, 0);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'PT Sans', sans-serif; */
}

.home,
.projects,
.about,
.sign-up {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* font-size: 3rem; */
  font-family: 'Sarpanch', sans-serif;
}

.projects > h1,
.about > h1 {
  color: white;
  font-size: 100px;
  margin-top: -100px;
  font-family: 'Sarpanch', sans-serif;
}

.projects-btns {
  margin-top: 18px;
  color: white;
  font-size: medium;
}

.projects-btns:hover {
  cursor: pointer;
  transform: scale(1.4);
  transition: all 0.5s ease-out;
  color: white;
}

.projects-btns .btn {
  margin: 6px;
}

.projects {
  /* background-image: url('https://sdo.gsfc.nasa.gov/assets/img/browse/2020/07/20/20200720_001311_4096_304211171.jpg'); */
  /* background-image: url('/src/images/BlueSun1.jpg'); */

  /* background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: rgb(33, 33, 33);
  color: #fff;
  font-size: 100px;
}

.about {
  background-image: url('/src/images/RedSun1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('https://sdo.gsfc.nasa.gov/assets/img/browse/2020/07/20/20200720_021753_4096_0193.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about-me {
  background-color: black;
  
  color: white;
  height: 95vh;  
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* font-size: 3rem; */
  font-family:  sans-serif;
  /* padding: 100px; */
  font-family: 'Nanum Gothic', sans-serif;
}

.about-me-img {  
  max-width: 90%;
  margin: 3px;
  height: auto;
  object-fit: contain;  
}

.about-me-text {
  text-align: center;
  max-width: 90%;
  /* min-height: 450px; */
}


/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */


@media screen and (max-width: 960px) {
  .projects > h1 {    
    font-size: 70px;
    margin-top: -150px;   
  }
}

@media screen and (max-width: 768px) {

    html,body
  {
      /* width: 100%; */
      /* height: 100%; */
      /* margin: 0px;
      padding: 0px; */
      overflow-x: hidden; 
  }

  .projects > h1 {   
    /* background-color: green;  */
    font-size: 30px;
    margin-top: -150px;   
  }

  .about > h1 {   
    /* background-color: green;  */
    font-size: 30px;
    margin-top: -150px;   
  }

  .hero-container > p {
    font-size: 15px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .about-me-text {
    /* text-align: center;
    max-width: 90%; */
    min-height: 450px;
  }
}

canvas {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 800px;
  border: solid grey 1px;
  box-shadow: 0 0 15px rgba(145, 145, 145, 0.838);
}


#game {
  display: flex;
  height: 74vh;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding:5px;
  background-color: black;
}